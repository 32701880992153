import { Box, CardMedia, Container, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import coverAbout from './../../assets/panner/Explore.png'
import logo from './../../assets/panner/6.png'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'

const Panner = () => {
    const {t} = useTranslation()
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{ position: 'relative', padding: '60px 0', display: 'flex', alignItems: 'center', height: '40dvh' }} >
                <div className="overlay" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.2)', zIndex: '1' }} ></div>
                <CardMedia component={'img'} src={coverAbout} sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} />
                <Container sx={{ zIndex: '2', position: 'relative'}} >
                    <Box className='flex-col'  sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:4}}>
                        <Typography variant={isMobile ? 'h5' : 'h4'} color={'primary.text'} textAlign={'center'}>
                            {t('panr')}
                        </Typography>
                        <CardMedia component={'img'} src={logo} sx={{ objectFit:'cover', width:200 }} />
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Panner