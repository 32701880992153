import a1 from './../assets/AlbumV/VID-20240104-WA0002.mp4'
import a2 from './../assets/AlbumV/VID-20240104-WA0003.mp4'
import a3 from './../assets/AlbumV/VID-20240104-WA0004.mp4'
import a4 from './../assets/AlbumV/VID-20240104-WA0005.mp4'
import a5 from './../assets/AlbumV/VID-20240104-WA0007.mp4'
import a6 from './../assets/AlbumV/VID-20240106-WA0003.mp4'
import a7 from './../assets/AlbumV/VID-20240106-WA0004.mp4'
import a8 from './../assets/AlbumV/VID-20240106-WA0005.mp4'
import a9 from './../assets/AlbumV/VID-20240106-WA0006.mp4'
import a10 from './../assets/AlbumV/VID-20240106-WA0007.mp4'
import a11 from './../assets/AlbumV/c1.mp4'
import a12 from './../assets/AlbumV/c2.mp4'
import a13 from './../assets/AlbumV/c3.mp4'
import a14 from './../assets/AlbumV/c4.mp4'
import a15 from './../assets/AlbumV/c5.mp4'
import v1 from './../assets/AlbumV/v1.mp4'
import v2 from './../assets/AlbumV/v2.mp4'
import v3 from './../assets/AlbumV/v3.mp4'
import v4 from './../assets/AlbumV/v4.mp4'
import v5 from './../assets/AlbumV/v5.mp4'
import v6 from './../assets/AlbumV/v6.mp4'
import v7 from './../assets/AlbumV/v7.mp4'
import v8 from './../assets/AlbumV/v8.mp4'
import v9 from './../assets/AlbumV/v9.mp4'
import v10 from './../assets/AlbumV/v10.mp4'
import v11 from './../assets/AlbumV/v11.mp4'
import v12 from './../assets/AlbumV/v12.mp4'
import v13 from './../assets/AlbumV/v13.mp4'
import v14 from './../assets/AlbumV/v14.mp4'
import v15 from './../assets/AlbumV/v15.mp4'
import v16 from './../assets/AlbumV/v16.mp4'

export const videos = [
    a1,
    a2,
    a3,
    a4,
    a5,
    a6,
    a7,
    a8,
    a9,
    a10,
    a11,
    a12,
    a13,
    a14,
    a15,
    v1,
    v2,
    v3,
    v4,
    v5,
    v6,
    v7,
    v8,
    v9,
    v10,
    v11,
    v12,
    v13,
    v14,
    v15,
    v16,
]