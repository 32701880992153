import React from 'react'
import Header from '../components/HeaderSlider/Header'
import Service from '../components/Service/Service'
import OurFeatures from '../components/OurFeatures/OurFeatures'
import Reviews from '../components/Reviews/Reviews'
import OurStatistics from '../components/OurStatistics/OurStatistics'
import Album from '../components/Album/Album'
import Section from '../components/Ui/Section'
import AlbumV from '../components/Album/AlbumV'
import Panner from '../components/Panner/Panner'
import { useTranslation } from 'react-i18next'

const Home = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <Service />
            <Panner />
            <OurFeatures />
            <Section title={t("Slaider.one")} >
                <Album />
            </Section>
            <Reviews />
            <Section title={t("Slaider.tow")} >
                <AlbumV />
            </Section>
            <OurStatistics />
        </div>
    )
}

export default Home