import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { videos } from '../../constants/videos'
const AlbumV = () => {
    return (
        <>
            <Carousel
                height={400}
                autoPlay={false}
                sx={{
                    marginTop: "25px",
                    width: { md: "100%", xs: "100%" },
                    margin: "auto"
                }}>
                {videos?.map((res, i) => <video key={i} autoPlay={false} controls={true} src={res} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} >
                    Your browser does not support the video tag.
                </video>)}
            </Carousel>
        </>
    )
}

export default AlbumV